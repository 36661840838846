<template>
    <div class="card">
        <div v-if="isLoading">
            <loader />
        </div>
        <div class="table-responsive" v-else-if="donations.length > 0">
            <table class="table mb-0">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Name</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(donation, i) in donations" :key="i">
                        <th scope="row">{{ makeDate(donation.createdAt) }}</th>
                        <td>{{ donation.name }}</td>
                        <td>{{ donation.currency + ` ` + donation.amount }}</td>
                        <td>{{ donation.status }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else>No donations found</div>
        <!-- <pre>{{ donations }}</pre> -->
    </div>
</template>

<script>
export default {
    data(){
        return {
            donations: [],
            isLoading: false,
        }
    },
    created(){
        this.loadDonation()
    },
    methods: {
        async loadDonation(){
            this.isLoading = true
            const snapshot = await this.$db.collection("donations")
                .orderBy("createdAt", "desc")
                .get()
            snapshot.forEach(doc => {
                this.donations.push({
                    id: doc.id,
                    ...doc.data()
                })
            })
            this.isLoading = false
        },
        makeDate(date){
            return date && typeof date !== undefined ? this.$moment(date.toDate()).format("DD/MM/YYYY HH:mm:ss a") : "NIL"
        }
    },
    components: {
        "loader": () => import("@/components/Loaders/Processing.vue")
    }
}
</script>